import React, {FC, useContext, useRef} from 'react';
import {    IonHeader,
            IonToolbar,
            IonTitle,
            IonContent,
            IonList,
            IonItem,
            IonIcon,
            IonLabel,
            IonMenu,
            IonGrid,
            IonRow,
            IonCol,
            IonRouterLink,    
            IonFooter} from '@ionic/react';
import {    homeOutline,
            listCircleOutline,
            starOutline,
            alarmOutline,
            personOutline,
            chatbubbleEllipsesOutline,
            personCircleOutline,
            logOutOutline,
            menu, 
            personAddOutline} from 'ionicons/icons';
import {    AppContext  } from '../pages/appProvider';
import logo from '../assets/logo.jpg';
import { useHistory } from 'react-router';
import conversationService from '../pages/conversation/conversationService';



const  HeaderWithContent: FC = ({children}) => {
    const {deconnexion,messages_non_lu,si_authentique, moi, parametre, gerer_erreur, gerer_log, definir_conversations} = useContext(AppContext);
    const menuRef = useRef<HTMLIonMenuElement>(null);
    const history = useHistory();
    const deconnexionRef = useRef<HTMLIonRouterLinkElement>(null);
    const deconnecter = async () => {
            await deconnexion();
            history.push("/");
    }

    const init_conversation = () => {
        if (moi && moi.id && moi.level && parametre) {

            if (moi.level === "e20d2fe3d0db14b745b1") {
                
                conversationService.tous_les_conversations_avec_message_non_lu(moi.id)
                    .then(reponse => {
                        definir_conversations(reponse.data);
                    })
                    .catch(erreur => {
                        gerer_erreur("erreur : ",erreur);
                    })

            } else if (moi.level === "19631de8f0500b7bda26") {
                conversationService.conversation_avec_message_non_lu_admin(moi.id, parametre.admin_message_id)
                    .then(reponse => {
                        reponse.data[0].firstname = "";
                        reponse.data[0].lastname = "ADMINISTRATEUR";
                        definir_conversations(reponse.data);
                    })
                    .catch(erreur => {
                        gerer_erreur("erreur : ",erreur);
                    })
            }
        }
    }
    
    return (<>
                <IonHeader className='ion-no-border' color='light'>
                    <IonToolbar className='ion-no-border bg-white' color='light'>
                        <IonGrid className='bg-white'>
                            <IonRow className='ion-align-items-center'>
                                <IonCol onClick={() => menuRef && menuRef.current ? menuRef.current.toggle() : ""}>
                                    <IonIcon className="text-[36px] mobile320:text-[42px] mobile384:[48px]" icon={menu}/>
                                </IonCol>
                                <IonCol size='8'>
                                    <IonRouterLink routerLink='/accueil'>
                                        <div className="shrink-0 w-full bg-white text-center">
                                            <img className="mx-auto h-8 mobile320:h-10 mobile384:h-14" src={logo}/>
                                        </div>
                                    </IonRouterLink>
                                </IonCol>
                                <IonCol>
                                    {!si_authentique ?
                                    <IonRouterLink routerLink='/connexion'>
                                        <IonIcon  className='text-gray-600 text-[32px] mobile320:text-[36px] mobile384:text-[44px]'  icon={personCircleOutline}/>
                                    </IonRouterLink>
                                    : null}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonHeader>
                <IonContent className='relative ion-no-border'>
                    <IonRouterLink ref={deconnexionRef} hidden routerLink='/accueil'></IonRouterLink>
                    <IonMenu ref={menuRef} contentId="main" side="start" menuId="custom" className="fixed my-custom-menu">
                        <IonHeader>
                        <IonToolbar className="bg-[#EA491D]">
                            <IonTitle>Menu</IonTitle>
                        </IonToolbar>
                        </IonHeader>
                        <IonContent>
                        <IonList>
                            {!si_authentique ?              
                            <IonItem routerLink='/accueil' onClick={() => menuRef && menuRef.current ? menuRef.current.close() : ""}>
                                <IonIcon className="text-[#EA491D] mr-5" icon={homeOutline}/>
                                <IonLabel>Accueil</IonLabel>
                            </IonItem>
                            : null}

                            {!si_authentique ?
                            <IonItem routerLink='/inscription' onClick={() => menuRef && menuRef.current ? menuRef.current.close() : ""}>
                                <IonIcon className="text-[#EA491D] mr-5" icon={personAddOutline}/>
                                <IonLabel>Inscription</IonLabel>
                            </IonItem>
                            : null}

                            <IonItem routerLink='/playlists' onClick={() => menuRef && menuRef.current ? menuRef.current.close() : ""}>
                                <IonIcon className="text-[#EA491D] mr-5" icon={listCircleOutline}/>
                                <IonLabel>Ambiance Musicale</IonLabel>
                            </IonItem>

                            {si_authentique ?
                            <IonItem routerLink='/favoris' onClick={() => menuRef && menuRef.current ? menuRef.current.close() : ""}>
                                <IonIcon className="text-[#EA491D] mr-5" icon={starOutline}/>
                                <IonLabel>Mes favoris</IonLabel>
                            </IonItem>
                            : null}
                            {si_authentique ?
                            <IonItem routerLink="/programmations" onClick={() => menuRef && menuRef.current ? menuRef.current.close() : ""}>
                                <IonIcon className="text-[#EA491D] mr-5" icon={alarmOutline}/>
                                <IonLabel>Programmations</IonLabel>
                            </IonItem>
                            : null}
                            {si_authentique ? 
                            <IonItem routerLink="/coordonnées" onClick={() => menuRef && menuRef.current ? menuRef.current.close() : ""}>
                                <IonIcon className="text-[#EA491D] mr-5" icon={personOutline}/>
                                <IonLabel>Mes coordonnées</IonLabel>
                            </IonItem>
                            :null}
                            {si_authentique ? 
                            <IonItem routerLink="/conversations"  onClick={() => {init_conversation(); return menuRef && menuRef.current ? menuRef.current.close() : ""}}>
                                <IonIcon className="text-[#EA491D] mr-5" icon={chatbubbleEllipsesOutline}/>
                                <IonLabel>
                                    <div className='flex items-center justify-between'>
                                            <span>Nous écrire</span>
                                            {messages_non_lu && messages_non_lu.length > 0 ? <div className='bg-[#EA491D] text-white text-sm px-2 rounded'><span>{messages_non_lu.length}</span></div> : null} 
                                    </div>
                                </IonLabel>
                            </IonItem>
                            :null}
                            {si_authentique ? 
                            <IonItem onClick={() => {deconnecter(); return menuRef && menuRef.current ? menuRef.current.close() : ""}}>
                                <IonIcon className="text-[#EA491D] mr-5" icon={logOutOutline}/>
                                <IonLabel>Déconnexion</IonLabel>
                            </IonItem>
                            :null}
                        </IonList>
                        </IonContent >
                        <IonFooter></IonFooter>
                    </IonMenu>
                    {children}
                </IonContent>
                <IonFooter></IonFooter>
            </>)
}

export default HeaderWithContent;