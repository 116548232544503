import React,{useContext, useRef, useEffect, useState} from 'react';
import {IonPage, IonList, IonItem, IonTitle, IonHeader, IonContent,IonButton, IonLabel, IonToolbar, IonIcon, IonGrid, IonRow, IonCol, IonThumbnail, IonRange, IonRouterLink} from '@ionic/react';
import {menu, personCircleOutline,playSkipBackCircleOutline,playSkipBack,playSkipForward,shuffleOutline,starOutline, playSkipForwardCircleOutline, playBackCircleOutline, playForwardCircleOutline, playCircleOutline} from 'ionicons/icons';
import logo from '../../assets/logo.jpg';
import { Formik, FormikComputedProps, FormikHelpers, FormikProps, FormikState, FormikValues } from 'formik';
import * as Yup from 'yup';
import coordonneesService from './coordonneesService';
import {AppContext} from '../appProvider';
import HeaderWithContent from '../../components/headerWithContent';

const Coordonnees: React.FC = () => {
    const {moi,definir_toast_message, gerer_erreur, gerer_log} = useContext(AppContext);
    const formikRef = useRef<FormikProps<any>>(null);

    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");

    useEffect(() => {
        if (moi && moi.id) {
            coordonneesService.obtenir_coordonnees(moi.id)
                .then(reponse => {
                    gerer_log("coordronnees raw : ", reponse);
                    if (formikRef && formikRef.current) {
                        formikRef.current.setValues({
                            company: reponse.data.Customer.company,
                            username: reponse.data.username,
                            firstname: reponse.data.firstname,
                            lastname: reponse.data.lastname,
                            city: reponse.data.Customer.city,
                            address: reponse.data.Customer.address,
                            postal_code: reponse.data.Customer.postal_code,
                            siret: reponse.data.Customer.siret,
                            crypte: reponse.data.Customer.crypte,
                            email: reponse.data.email,
                            phone: reponse.data.Customer.phone,
                        })
                    }
                })
                .catch(erreur => {
                    gerer_erreur("",erreur)
                })
        }
    },[moi, formikRef])

    const handleDidFocus = (field: string) => {
        setDidFocus(true);
        setCurrentField(field)
    }

    return (
            <IonContent>
            {/* <IonPage>
                <HeaderWithContent> */}
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            company: "",
                            username: "",
                            firstname: "",
                            lastname: "",
                            city: "",
                            address: "",
                            postal_code: "",
                            siret: "",
                            crypte: "",
                            email: "",
                            phone: "",
                        }}

                        validationSchema={Yup.object({
                            company: Yup.string().required("Nom de l'etablissement requis"),
                            username: Yup.string().max(250,"doit etre inferieur a 255 caractères").required("Nom d'utilisateur requis"),
                            firstname: Yup.string().max(250,"doit etre inferieur a 255 caractères").required("Prenom requis"),
                            lastname: Yup.string().max(250,"doit etre inferieur a 255 caractères").required("Nom requis"),
                            city: Yup.string().required("Ville requis"),
                            address: Yup.string().required("Adresse requis"),
                            postal_code: Yup.string().required("code postale requis"),
                            siret: Yup.string().matches(/^[0-9]{14}$/,"doit etre une serie de 14 chiffres"),
                            email: Yup.string().email("Doit etre un email valide").required("Email requis"),
                            phone: Yup.string().required("Telephone requis"),
                        })}

                        onSubmit={(values: any) => {
                            coordonneesService.sauvegarder_coordonnees(moi.id,values)
                                .then(reponse => {
                                    definir_toast_message(reponse.status,reponse.message)
                                })
                                .catch(erreur => {
                                    if (erreur && erreur?.response?.data?.status) {
                                        definir_toast_message(erreur.response.data.status, erreur.response.data.message);
                                    } else {
                                        if (erreur.code == "ERR_NETWORK") {
                                            definir_toast_message(400, "Verifier votre connexion internet");
                                        } else {
                                            definir_toast_message(400, erreur.message);
                                        }
                                    }
                                })
                        }}
                    >{(formik) => (
                        <div>
                            <form className='p-10 space-y-5' onSubmit={formik.handleSubmit}>
                            <div className='space-y-3'>
                                    <label className="block" htmlFor="company">Nom de l'établissement</label>
                                    <input  type="text" 
                                            id="company"
                                            {...formik.getFieldProps("company")}
                                            onFocus={() => handleDidFocus("company")}
                                            className={`${!!didFocus && currentField == "nom" && formik.errors.company || formik.touched.company && formik.errors.company ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                    {!!didFocus && currentField == "company" && formik.errors.company || formik.touched.company && formik.errors.company ?<span className="text-red-500 text-sm">{formik.errors.company}</span> : null }
                                    
                                </div>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor="username">Nom d'utilisateur</label>
                                    <input  type="text" 
                                            id="username"
                                            {...formik.getFieldProps("username")}
                                            onFocus={() => handleDidFocus("username")}
                                            className={`${!!didFocus && currentField == "username" && formik.errors.username || formik.touched.username && formik.errors.username ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                    {!!didFocus && currentField == "username" && formik.errors.username || formik.touched.username && formik.errors.username ?<span className="text-red-500 text-sm">{formik.errors.username}</span> : null }
                                </div>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor="lastname">Nom</label>
                                    <input  type="text" 
                                            id="lastname"
                                            {...formik.getFieldProps("lastname")}
                                            onFocus={() => handleDidFocus("lastname")}
                                            className={`${!!didFocus && currentField == "lastname" && formik.errors.lastname || formik.touched.lastname && formik.errors.lastname ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                    {!!didFocus && currentField == "lastname" && formik.errors.lastname || formik.touched.lastname && formik.errors.lastname ?<span className="text-red-500 text-sm">{formik.errors.lastname}</span> : null }
                                </div>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor="firstname">Prénom</label>
                                    <input  type="text" 
                                            id="firstname"
                                            {...formik.getFieldProps("firstname")}
                                            onFocus={() => handleDidFocus("firstname")}
                                            className={`${!!didFocus && currentField == "firstname" && formik.errors.firstname || formik.touched.firstname && formik.errors.firstname ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                    {!!didFocus && currentField == "firstname" && formik.errors.firstname || formik.touched.firstname && formik.errors.firstname ?<span className="text-red-500 text-sm">{formik.errors.firstname}</span> : null }
                                </div>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor='address'>Adresse</label>
                                    <input  type="text"
                                            id="address"
                                            {...formik.getFieldProps("address")}
                                            onFocus={() => handleDidFocus("address")}
                                            className={`${!!didFocus && currentField == "address" && formik.errors.address || formik.touched.address && formik.errors.address ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                    {!!didFocus && currentField == "address" && formik.errors.address || formik.touched.address && formik.errors.address ?<span className="text-red-500 text-sm">{formik.errors.address}</span> : null }
                                </div>
                                <div className='space-y-3'>
                                    <label className="postal_code" htmlFor='postal_code'>Code postal</label>
                                    <input  type="text"
                                            id="postal_code"
                                            {...formik.getFieldProps("postal_code")} 
                                            onFocus={() => handleDidFocus("postal_code")}
                                            className={`${!!didFocus && currentField == "postal_code" && formik.errors.postal_code || formik.touched.postal_code && formik.errors.postal_code ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "postal_code" && formik.errors.postal_code || formik.touched.postal_code && formik.errors.postal_code ?<span className="text-red-500 text-sm">{formik.errors.postal_code}</span> : null }
                                </div>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor='city'>Ville</label>
                                    <input  type="text" 
                                            id="city"
                                            {...formik.getFieldProps("city")}
                                            onFocus={() => handleDidFocus("city")}
                                            className={`${!!didFocus && currentField == "city" && formik.errors.city || formik.touched.city && formik.errors.city ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "city" && formik.errors.city || formik.touched.city && formik.errors.city ?<span className="text-red-500 text-sm">{formik.errors.city}</span> : null }
                                </div>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor='phone'>Téléphone</label>
                                    <input  type="text"
                                            id="phone"
                                            {...formik.getFieldProps("phone")} 
                                            onFocus={() => handleDidFocus("phone")}
                                            className={`${!!didFocus && currentField == "phone" && formik.errors.phone || formik.touched.phone && formik.errors.phone ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "phone" && formik.errors.phone || formik.touched.phone && formik.errors.phone ?<span className="text-red-500 text-sm">{formik.errors.phone}</span> : null }
                                </div>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor='email'>Email</label>
                                    <input  type="email" 
                                            id="email"
                                            {...formik.getFieldProps("email")}
                                            onFocus={() => handleDidFocus("email")}
                                            className={`${!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ?<span className="text-red-500 text-sm">{formik.errors.email}</span> : null }
                                </div>
                                <div className='space-y-3'>
                                    <label className="block">Siret (facultatif)</label>
                                    <input  type="text"
                                            id="siret"
                                            {...formik.getFieldProps("siret")}
                                            onFocus={() => handleDidFocus("siret")}
                                            className={`${!!didFocus && currentField == "siret" && formik.errors.siret || formik.touched.siret && formik.errors.siret ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "siret" && formik.errors.siret || formik.touched.siret && formik.errors.siret ?<span className="text-red-500 text-sm">{formik.errors.siret}</span> : null }
                                </div>
                                <div className=''>
                                    <button className="block mx-auto bg-[#EA491D] rounded text-white px-4 py-2 w-full" type="submit">Sauvegarder</button>
                                </div>
                            </form>
                        </div>
                    )}</Formik>
                {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>);
}

export default Coordonnees;