import axios from 'axios';
import config from '../../config/config.json';


class CoordonneesService {
    async obtenir_coordonnees(id: number) {
        const {data} = await axios.get(`${config.API_URL}/API/client/${id}`);
        return data;
    }
    async sauvegarder_coordonnees(id: number,values: object) {
        const {data} = await axios.put(`${config.API_URL}/API/client/update/${id}`,values);
        return data;
    }
}

export default new CoordonneesService();