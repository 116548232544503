import React, {useEffect, useContext, useState, useRef} from 'react';
import {IonList, IonPage, IonItem, IonIcon, IonContent} from '@ionic/react';
import HeaderWithContent from '../../components/headerWithContent';
import {AppContext} from '../appProvider';
import moment from 'moment';
import conversationService from './conversationService';
import {mailOutline} from 'ionicons/icons';
import messageService from '../message/messageService';
moment.locale('fr');

interface MessageType {
    id: number,
    content: string,
    sender: number,
    to: number,
    to_view: number,
    date_time: string,
    created_at: string,
    updated_at: string
}

interface User {
    id: number,
    firstname: string,
    lastname: string,
    level: string,
    email: string,
    message_non_lu_count: number
}

const Conversation: React.FC = () => {
    const {moi,parametre,definir_messages_non_lu, gerer_erreur,definir_toast_message,definir_conversation_courant, gerer_log, tous_les_messages, mettre_a_jour_messages, conversations, definir_conversations} = useContext(AppContext);

    useEffect(()=> {

        if (moi && moi.id && moi.level && parametre) {
            if (moi.level === "e20d2fe3d0db14b745b1") {
                
                conversationService.tous_les_conversations_avec_message_non_lu(moi.id)
                    .then(reponse => {
                        definir_conversations(reponse.data);
                    })
                    .catch(erreur => {
                        gerer_erreur("erreur : ",erreur);
                    })

            } else if (moi.level === "19631de8f0500b7bda26") {
                conversationService.conversation_avec_message_non_lu_admin(moi.id, parametre.admin_message_id)
                    .then(reponse => {
                        reponse.data[0].firstname = "";
                        reponse.data[0].lastname = "ADMINISTRATEUR";
                        definir_conversations(reponse.data);
                    })
                    .catch(erreur => {
                        gerer_erreur("erreur : ",erreur);
                    })
            }
        }
    }, [moi, parametre]);

    const voir_message = (values: any) => {
        conversationService.voir_tous_les_message_non_lu(values)
            .then(response => {
                if (moi.level === "e20d2fe3d0db14b745b1") {
                    definir_conversations(response.data);
                    definir_messages_non_lu(response.tous_les_message_non_lu)
                } else if (moi.level === "19631de8f0500b7bda26") {
                    let conversation_admin  = response.data.find((conversation: any) => conversation.id === parametre.admin_message_id);
                    conversation_admin.lastname="ADMINISTRATEUR";
                    conversation_admin.firstname="";
                    definir_conversations([conversation_admin]);

                    let admin_messages_non_lu = response.tous_les_message_non_lu.filter((message: any) => message.sender === parametre.admin_message_id);
                    definir_messages_non_lu(admin_messages_non_lu);
                }
            })
            .catch(erreur => {
                console.error(erreur);
            })
    }

    return (
            <IonContent>
                {/* <IonPage>
                 <HeaderWithContent> */}
                        <IonList>
                            {conversations && conversations.length ?
                                conversations.map((conversation: User) => (
                                    <IonItem routerLink='/message' onClick={() => {definir_conversation_courant({sender: moi.id, to: conversation.id}, voir_message({sender: conversation.id,to: moi.id}))}}>
                                        <div className='w-full flex items-center justify-between'>
                                            <div className='flex items-center p-2 space-x-3'>
                                                <div className='rounded-full bg-[#EA491D] grid place-content-center w-12 h-12'>
                                                    <IonIcon icon={mailOutline} size="large" className='text-white'/>
                                                </div>
                                                <div>
                                                    <div><h2 className='text-sm text-gray-700'>{`${conversation.lastname} ${conversation.firstname}`}</h2></div>
                                                    <p className='text-xs text-slate-400'>{conversation.email}</p>
                                                </div>
                                            </div>
                                            {conversation.message_non_lu_count > 0 ? <div className='bg-[#EA491D] text-white text-sm px-2 rounded'><span>{ conversation.message_non_lu_count}</span></div> : null}
                                        </div>
                                    </IonItem>
                                ))
                            : <IonItem><p className='w-full text-center'>Pas de conversations</p></IonItem>}
                        </IonList>
                {/* </HeaderWithContent>
            </IonPage> */}
                    </IonContent>
            )
}

export default Conversation;