import React from "react";
import {Storage} from '@ionic/storage';
import {decodeJwt} from 'jose';
import musiqueService from './liste_musique/musiqueService'
import { WebradioType } from "./types";


type Props = {} | null

interface UserCourantType {
    id: number,
    firstname?: string,
    lastname?: string
}

interface Playlist {
    id: number,
    name: string,
    categorie: string,
    readings_number: number,
    type: string,
    cover: string,
    created_at: string,
    updated_at: string
}

interface MusiqueType {
    id: number,
    title: string,
    categorie: string,
    duration: string,
    filename: string,
    created_at: string,
    updated_at: string,
    playlist_id: number,
}

interface FavorisType {
    id: number,
    user_id: number,
    sound_id: number,
    Sound?: MusiqueType,
    created_at: string,
    updated_at: string
}



interface FavorisWebradioType {
    id: number,
    user_id: number,
    webradio_id: number,
    Weradio: MusiqueType,
    created_at: string,
    updated_at: string
}

interface MessageType {
    id: number,
    content: string,
    sender: number,
    to: number,
    to_view: number,
    date_time: string,
    created_at: string,
    updated_at: string
}

interface ParametreType {
    admin_message_id: number,
    playlist_anniversaire: number
}
interface State {
    default_sound_anniversaire: MusiqueType | null,
    webradios: WebradioType[],
    playlist_precedent: MusiqueType[],
    en_train_de_lire_playlist_anniversaire: boolean,
    messages_non_lu: object[],
    conversation_courant: object | null,
    conversations: object[],
    programmations_du_jour: object[],
    programmations: object[],
    aleatoire: boolean,
    favoris: FavorisType[],
    favoris_webradios: FavorisWebradioType[],
    tous_les_messages: MessageType[],
    moi: UserCourantType | null,
    si_authentique: boolean,
    etat_toast: boolean,
    toast: {statut: number, message: string} | null,
    etat_menu: boolean,
    duration: number,
    position: number,
    pause: boolean,
    musique_courant: any | null | undefined,
    meta_playlist_courant: object | null | undefined,
    playlist_courant: MusiqueType[],
    temps_restant: number,
    temps_ecoule: number,
    temps_courant: number,
    store: Storage | null,
    token: string | null,
    parametre: ParametreType | null,
    modal_programme: boolean,
}



interface AppContextInterface {
    default_sound_anniversaire: MusiqueType  | null,
    webradios: WebradioType[],
    playlist_precedent: MusiqueType[],
    en_train_de_lire_playlist_anniversaire: boolean,
    messages_non_lu: object[],
    conversation_courant: object | null,
    conversations: object[],
    aleatoire: boolean,
    favoris: FavorisType[],
    favoris_webradios: FavorisWebradioType[],
    modal_programme: boolean,
    tous_les_messages: MessageType[],
    moi: UserCourantType | null,
    si_authentique: boolean,
    token: string | null,
    store: Storage | null,
    etat_toast: boolean,
    toast: {statut: number, message: string} | null,
    etat_menu: boolean,
    duration: number,
    position: number,
    pause: boolean,
    musique_courant: {} | null | undefined,
    meta_playlist_courant: {} | null | undefined,
    playlist_courant: MusiqueType[],
    temps_restant: number,
    temps_ecoule: number,
    temps_courant: number,
    parametre: ParametreType | null,
    programmations: object[],
    programmations_du_jour: object[],
    definir_temps_courant: Function,
    definir_duration: Function,
    definir_playlist_courant: Function,
    definir_musique_courant: Function,
    definir_meta_playlist_courant: Function,
    mettre_musique_suivant: Function,
    mettre_musique_precedent: Function,
    definir_temps: Function,
    mettre_en_pause: Function,
    definir_etat_menu: Function,
    fermer_toast: Function,
    definir_toast_message: Function,
    init_connexion: Function,
    deconnexion: Function,
    gerer_erreur: Function,
    gerer_log: Function,
    mettre_a_jour_messages: Function,
    toggle_modal_programme: Function,
    mis_a_jour_favoris: Function,
    mis_a_jour_favoris_webradios: Function,
    toggle_aleatoire: Function,
    definir_programmations: Function,
    definir_programmations_du_jour: Function,
    definir_pause: Function,
    definir_conversations: Function,
    definir_conversation_courant: Function,
    definir_messages: Function,
    definir_messages_non_lu: Function,
    ajouter_message_non_lu: Function,
    lire_playlist_anniversaire: Function,
    definir_playlist_precedent: Function,
    get_token: Function,
    setWebradios: Function,
    set_default_sound_anniversaire: Function
}

interface Musique {
    id: number,
    titre: string,
    type: string,
    size: number,
    url: string,
    createdAt: string,
    updatedAt: string
}

export const storage = new Storage();
export const AppContext = React.createContext<any>(null);

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

class AppProvider extends React.Component<Props,State> {
    state: State
    constructor (props: Props) {
        super(props);
        this.state= {
            default_sound_anniversaire: null,
            webradios: [],
            playlist_precedent: [],
            messages_non_lu: [],
            conversation_courant: null,
            conversations: [],
            programmations_du_jour: [],
            programmations: [],
            aleatoire: true,
            favoris: [],
            favoris_webradios : [],
            tous_les_messages: [],
            moi: null,
            toast: null,
            etat_toast: false,
            duration: 0,
            position: 0,
            pause: false,
            musique_courant: null,
            playlist_courant: [],
            meta_playlist_courant: null,
            temps_restant: 0,
            temps_ecoule: 0,
            temps_courant: 0,
            etat_menu: false,
            store: null,
            token: null,
            si_authentique: false,
            parametre:null,
            modal_programme: false,
            en_train_de_lire_playlist_anniversaire: false
        }
        this.definir_musique_courant = this.definir_musique_courant.bind(this);
        this.definir_playlist_courant = this.definir_playlist_courant.bind(this); 
        this.definir_temps = this.definir_temps.bind(this);
        this.mettre_en_pause = this.mettre_en_pause.bind(this);
        this.mettre_musique_suivant = this.mettre_musique_suivant.bind(this);
        this.mettre_musique_precedent = this.mettre_musique_precedent.bind(this);
        this.definir_meta_playlist_courant = this.definir_meta_playlist_courant.bind(this);
        this.definir_duration = this.definir_duration.bind(this);
        this.definir_temps_courant = this.definir_temps_courant.bind(this);
        this.definir_etat_menu = this.definir_etat_menu.bind(this);
        this.fermer_toast = this.fermer_toast.bind(this);
        this.definir_toast_message = this.definir_toast_message.bind(this);
        this.si_active = this.si_active.bind(this);
        this.deconnexion = this.deconnexion.bind(this);
        this.init_connexion = this.init_connexion.bind(this);
        this.gerer_erreur = this.gerer_erreur.bind(this);
        this.gerer_log = this.gerer_log.bind(this);
        this.mettre_a_jour_messages = this.mettre_a_jour_messages.bind(this);
        this.toggle_modal_programme = this.toggle_modal_programme.bind(this);
        this.mis_a_jour_favoris = this.mis_a_jour_favoris.bind(this);
        this.toggle_aleatoire = this.toggle_aleatoire.bind(this);
        this.definir_programmations = this.definir_programmations.bind(this);
        this.definir_programmations_du_jour = this.definir_programmations_du_jour.bind(this);
        this.definir_conversations = this.definir_conversations.bind(this);
        this.definir_conversation_courant = this.definir_conversation_courant.bind(this);
        this.definir_messages = this.definir_messages.bind(this);
        this.definir_messages_non_lu = this.definir_messages_non_lu.bind(this);
        this.ajouter_message_non_lu = this.ajouter_message_non_lu.bind(this);
        this.lire_playlist_anniversaire = this.lire_playlist_anniversaire.bind(this);
        this.definir_playlist_precedent = this.definir_playlist_precedent.bind(this);
        this.mis_a_jour_favoris_webradios = this.mis_a_jour_favoris_webradios.bind(this);
        this.get_token = this.get_token.bind(this);
        this.setWebradios = this.setWebradios.bind(this);
        this.set_default_sound_anniversaire = this.set_default_sound_anniversaire.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        let store = await storage.create();
        this.setState((prevState) => ({store}));
        this.si_active();
    }

    async si_active() {
        try {
            let store = await storage.create();
            let token = await store.get("token");
            if (token) {
                let jose_token: any = await decodeJwt(token);
                this.setState(prevState => ({token, si_authentique: true, moi: jose_token, parametre: jose_token?.setting}));
            } else {
                this.setState(prevState => ({token: null, si_authentique: false, parametre: null}));
            }
        } catch (erreur) {
            console.error(erreur)
        } 
    }

    async init_connexion(token: string) {
        let store = await storage.create();
        await store.set("token", token);
        let jose_token: any = await decodeJwt(token);
        this.setState(prevState => ({token, si_authentique: true, moi: jose_token, parametre: jose_token?.setting}));
    }

    async get_token() {
        let store = await storage.create();
        return await store.get("token");
    }

    async deconnexion() {
        let store = await storage.create();
        await store.clear();

        await this.setState((prevState) => ({
            default_sound_anniversaire: null,
            webradios: this.state.webradios.filter(webradio => (webradio.type == "DEMO")),
            playlist_precedent: [],
            messages_non_lu: [],
            conversation_courant: null,
            conversations: [],
            programmations_du_jour: [],
            programmations: [],
            aleatoire: true,
            favoris: [],
            favoris_webradios : [],
            tous_les_messages: [],
            moi: null,
            toast: null,
            etat_toast: false,
            duration: 0,
            position: 0,
            pause: false,
            musique_courant: null,
            playlist_courant: [],
            meta_playlist_courant: null,
            temps_restant: 0,
            temps_ecoule: 0,
            temps_courant: 0,
            etat_menu: false,
            store: null,
            token: null,
            si_authentique: false,
            parametre:null,
            modal_programme: false,
            en_train_de_lire_playlist_anniversaire: false
        }));

    }

    definir_musique_courant(musique: Musique, index: number) {
        this.setState(prevState => ({musique_courant: musique, position: index, pause: false}))
    }

    definir_playlist_courant(playlist: any) {
        this.setState(prevState => ({playlist_courant: playlist}))
    }

    definir_meta_playlist_courant(meta_playlist: any) {
        this.setState(prevState => ({meta_playlist_courant: meta_playlist}))
    }

    definir_duration(duration: number) {
        this.setState(prevState => ({duration}));
    }

    mettre_musique_precedent() {
        try {
            if (this.state.musique_courant && this.state.musique_courant.categorie &&  this.state.musique_courant.categorie != "WEBRADIO") {
                if (!this.state.aleatoire) {
                    if (this.state.playlist_courant && this.state.playlist_courant.length) {
                        if (this.state.position === 0) {
                            this.setState((prevState: State) => ({musique_courant: prevState.playlist_courant && prevState.playlist_courant ? prevState.playlist_courant[prevState.playlist_courant.length -1] : prevState.musique_courant, position: prevState.playlist_courant.length - 1,pause: false}));
                        }else {
                            this.setState(prevState => ({musique_courant: prevState.playlist_courant && prevState.playlist_courant.length ? prevState.playlist_courant[prevState.position - 1] : prevState.musique_courant,position: prevState.position - 1, pause: false}))
                        }   
                    }
                } else {
                    if (this.state.en_train_de_lire_playlist_anniversaire) {
                        if (this.state.playlist_precedent && this.state.playlist_precedent.length) {
                            let id_courant = getRandomInt(this.state.playlist_precedent.length - 1);
                            this.setState((prevState: State) => ({musique_courant: prevState.playlist_precedent[id_courant],playlist_courant: prevState.playlist_precedent,position: id_courant, pause: false, en_train_de_lire_playlist_anniversaire: false}));
                            if (this.state.moi && this.state.moi.id) {
                                musiqueService.ajout_stat(this.state.moi.id, this.state.playlist_precedent[id_courant].id);
                            }
                        }
                        
                    } else {
                        if (this.state.playlist_courant && this.state.playlist_courant.length) {
                            let id_courant = getRandomInt(this.state.playlist_courant.length - 1);
                            this.setState((prevState: State) => ({musique_courant: prevState.playlist_courant[id_courant],position: id_courant, pause: false}));
                            if (this.state.moi && this.state.moi.id) {
                                musiqueService.ajout_stat(this.state.moi.id, this.state.playlist_courant[id_courant].id);
                            }
                        }
                    }
                }
            }
        } catch (e) {

        }

    }
    
    mettre_musique_suivant() {
        try {
            if (this.state.musique_courant && this.state.musique_courant.categorie &&  this.state.musique_courant.categorie != "WEBRADIO") {
                if (!this.state.aleatoire) {
                    if (this.state.playlist_courant && this.state.playlist_courant.length) {
                        if (this.state.position === (this.state.playlist_courant?.length - 1)) {
                            this.setState((prevState: State) => ({musique_courant: prevState.playlist_courant && prevState.playlist_courant ? prevState.playlist_courant[0] : prevState.musique_courant, position: 0, pause: false}));
                        }else {
                            this.setState((prevState: State) => ({musique_courant: prevState.playlist_courant && prevState.playlist_courant.length ? prevState.playlist_courant[prevState.position + 1] : prevState.musique_courant,position: prevState.position + 1, pause: false}))
                        }
                    }
                } else {
                    if (this.state.en_train_de_lire_playlist_anniversaire) {
                        if (this.state.playlist_precedent && this.state.playlist_precedent.length) {
                            let id_courant = 0;
                            if (this.state.playlist_precedent[0].categorie == "WEBRADIO") {
                                id_courant = 0;
                            } else {
                                id_courant = getRandomInt(this.state.playlist_precedent.length - 1);
                            }
                            this.setState((prevState: State) => ({
                                musique_courant: prevState.playlist_precedent[id_courant], 
                                // playlist_courant: prevState.playlist_precedent,
                                position: id_courant, pause: false, 
                                en_train_de_lire_playlist_anniversaire: false}))
                            if (this.state.moi && this.state.moi.id) {
                                musiqueService.ajout_stat(this.state.moi.id, this.state.playlist_precedent[id_courant].id);
                            }
                        }
                    } else {
                        if (this.state.playlist_courant && this.state.playlist_courant.length) {
                            let id_courant = getRandomInt(this.state.playlist_courant.length - 1);
                            this.setState((prevState: State) => ({musique_courant: prevState.playlist_courant[id_courant],position: id_courant, pause: false}))
                            if (this.state.moi && this.state.moi.id) {
                                musiqueService.ajout_stat(this.state.moi.id, this.state.playlist_courant[id_courant].id);
                            }
                        }
                    }
                }
            }
        } catch (e) {
        }

    }

    definir_temps() {

    }

    mettre_en_pause() {
        this.setState(prevState => ({pause: !prevState.pause}));
    }

    definir_pause(valeur: boolean) {
        this.setState(prevState => ({pause: !prevState.pause}));
    }

    definir_temps_courant(temps: number) {
        this.setState(prevState => ({temps_courant: temps}))
    }

    definir_etat_menu() {
        this.setState(prevState => ({etat_menu: !prevState.etat_menu}));
    }

    fermer_toast() {
        this.setState(prevState => ({etat_toast: false, toast: null}));
    }

    definir_toast_message(statut: number,message: string) {
        this.setState(prevState => ({etat_toast: true, toast: {statut, message}}))
    }

    gerer_erreur(texte: string, erreur: any) {
        console.error(erreur);
    }

    gerer_log(texte: string, log: any) {
    }

    mettre_a_jour_messages(messages: MessageType[]) {
        if (this.state.tous_les_messages && this.state.tous_les_messages.length) {
            if (this.state.tous_les_messages[0].to === messages[0].to &&  this.state.tous_les_messages[0].sender == messages[0].sender) {
                this.setState(prevState => ({tous_les_messages: [...prevState.tous_les_messages, ...messages]}));
            } else if (this.state.tous_les_messages[0].to === messages[0].sender && this.state.tous_les_messages[0].sender === messages[0].to ) {
                this.setState(prevState => ({tous_les_messages: [...prevState.tous_les_messages, ...messages]}));
            }
        }
    }

    definir_messages(messages: MessageType[]) {
        this.setState(prevState => ({tous_les_messages: [...messages]}));
    }

    toggle_modal_programme() {
        this.setState(prevState => ({modal_programme: !prevState.modal_programme}));
    }

    mis_a_jour_favoris(favoris: FavorisType[]) {
        this.setState(prevState => ({favoris: [...favoris]}))
    }

    mis_a_jour_favoris_webradios(favoris_webradios: FavorisWebradioType[]) {
        this.setState(prevState => ({favoris_webradios: [...favoris_webradios]}));
    }
    
    toggle_aleatoire() {
        this.setState(prevState => ({aleatoire: !prevState.aleatoire}));
    }

    definir_programmations(programmes: object[]) {
        this.setState(prevState => ({programmations: programmes}));
    }

    definir_programmations_du_jour(programmes: object[]) {
        this.setState(prevState => ({programmations_du_jour: programmes}));
    }

    definir_conversations (conversations: object[]) {
        this.setState(prevState => ({conversations}))
    }

    definir_conversation_courant (conversation_courant: object | null) {
        this.setState(prevState => ({conversation_courant}))
    }

    definir_messages_non_lu(messages_non_lu: any) {
        this.setState(prevState => ({messages_non_lu}))
    }

    ajouter_message_non_lu(message_non_lu: any){
        this.setState(prevState => ({messages_non_lu: [...prevState.messages_non_lu, message_non_lu]}))
    }

    lire_playlist_anniversaire(bool: boolean) {
        this.setState(prevState => ({en_train_de_lire_playlist_anniversaire: bool}));
    }

    definir_playlist_precedent(playlist: MusiqueType[]) {
        this.setState(prevState => ({playlist_precedent: playlist}));
    }

    setWebradios(webradios: WebradioType[]) {
        this.setState(prevState => ({webradios}));
    }

    set_default_sound_anniversaire(sound:MusiqueType) {
        this.setState(prevState => ({default_sound_anniversaire: sound}));
    }

    render(): React.ReactNode {
        const { 
            default_sound_anniversaire,
            webradios,
            playlist_precedent, 
            en_train_de_lire_playlist_anniversaire, 
            messages_non_lu, 
            conversation_courant,
            conversations,
            programmations_du_jour,
            programmations,
            aleatoire,
            favoris,
            favoris_webradios,
            modal_programme, 
            parametre,
            tous_les_messages, 
            moi,
            si_authentique,
            store,
            token,
            etat_toast, 
            toast,
            etat_menu,
            duration,
            position,
            pause,
            musique_courant,
            temps_courant, 
            playlist_courant,
            meta_playlist_courant, 
            temps_restant, 
            temps_ecoule} = this.state;
        const {children} = this.props;
        const configOptions: AppContextInterface = {
            default_sound_anniversaire,
            webradios,
            playlist_precedent,
            messages_non_lu,
            conversation_courant,
            conversations,
            programmations,
            programmations_du_jour,
            aleatoire,
            favoris,
            favoris_webradios,
            modal_programme,
            parametre,
            moi,
            si_authentique,
            token,
            store,
            toast,
            etat_toast,
            etat_menu,
            duration,
            meta_playlist_courant,
            position,
            pause,
            temps_ecoule,
            temps_restant,
            temps_courant,
            musique_courant,
            playlist_courant,
            tous_les_messages,
            en_train_de_lire_playlist_anniversaire,
            set_default_sound_anniversaire: (sound: MusiqueType) => this.set_default_sound_anniversaire(sound),
            setWebradios: (webradios: WebradioType[]) => this.setWebradios(webradios),
            definir_messages_non_lu: (messages_non_lu: any) => this.definir_messages_non_lu(messages_non_lu),
            ajouter_message_non_lu: (message_non_lu: any) => this.ajouter_message_non_lu(message_non_lu),
            definir_conversation_courant: (conversation_courant: object | null) => this.definir_conversation_courant(conversation_courant),
            definir_conversations: (conversations: object[]) => this.definir_conversations(conversations),
            definir_pause: (valeur: boolean) => this.definir_pause(valeur),
            definir_programmations_du_jour: (programmes: object[]) => this.definir_programmations_du_jour(programmes),
            definir_programmations: (programmes: object[]) => this.definir_programmations(programmes),
            toggle_aleatoire: () => this.toggle_aleatoire(),
            deconnexion: () => this.deconnexion(),
            init_connexion: (token: string) => this.init_connexion(token),
            fermer_toast: () =>  this.fermer_toast(),
            definir_toast_message: (statut: number, message: string) => this.definir_toast_message(statut,message),
            definir_etat_menu: () => this.definir_etat_menu(),
            definir_duration: (duration: number) => this.definir_duration(duration),
            definir_meta_playlist_courant: (meta_playlist: any) => this.definir_meta_playlist_courant(meta_playlist),
            definir_playlist_courant: (playlist: any) => this.definir_playlist_courant(playlist),
            definir_musique_courant: (musique: Musique, index: number) => this.definir_musique_courant(musique, index),
            mettre_musique_suivant: () => this.mettre_musique_suivant(),
            mettre_musique_precedent: () => this.mettre_musique_precedent(),
            definir_temps: () => this.definir_temps(),
            mettre_en_pause: () => this.mettre_en_pause(),
            definir_temps_courant: (temps: number) => this.definir_temps_courant(temps),
            gerer_erreur: (texte: string,erreur: any) => this.gerer_erreur(texte,erreur),
            gerer_log: (texte: string, log: any) => this.gerer_log(texte, log),
            mettre_a_jour_messages: (messages: MessageType[]) => this.mettre_a_jour_messages(messages),
            toggle_modal_programme: () => this.toggle_modal_programme(),
            mis_a_jour_favoris: (favoris: FavorisType[]) => this.mis_a_jour_favoris(favoris),
            mis_a_jour_favoris_webradios: (favoris_webradios: FavorisWebradioType[]) => this.mis_a_jour_favoris_webradios(favoris_webradios),
            definir_messages: (messages: MessageType[]) => this.definir_messages(messages),
            lire_playlist_anniversaire: (bool: boolean) => this.lire_playlist_anniversaire(bool),
            definir_playlist_precedent: (playlist: MusiqueType[]) => this.definir_playlist_precedent(playlist),
            get_token: () => this.get_token()
        };
        
        return (<AppContext.Provider value={configOptions}>
                    {children}
                </AppContext.Provider>)
    }
}

export default AppProvider;