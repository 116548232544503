import {IonPage, IonHeader, IonContent, IonToolbar,IonRouterLink, IonButton, IonList, IonItem, IonLabel, IonTitle, IonIcon, IonListHeader, IonItemGroup, IonItemDivider, IonGrid, IonCol, IonRow, IonThumbnail} from '@ionic/react';
import {play,chevronBack, addOutline,pauseCircleOutline,star, menu, personCircleOutline, starOutline, playSkipBack, playSkipForward, playCircleOutline, shuffleOutline, trashOutline} from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assets/logo.jpg';
import {AppContext} from '../appProvider';
import programmationService from './programmationService';
import musiqueService from '../liste_musique/musiqueService';
import HeaderWithContent from '../../components/headerWithContent';
import MiniPlayer from '../../components/minPlayer';
import moment from 'moment';
import { WebradioType } from '../types';

interface ProgrammationType {
    id: number,
    user_id: number,
    playlist_id: number,
    time: string,
    Playlist?: PlaylistType,
    Webradio?: WebradioType,
    created_at: string,
    updated_at: string
}

interface PlaylistType {
    id: number,
    name: string,
    readings_number: number,
    type: string,
    cover: string,
    created_at: string,
    updated_at: string
}

const Programmation: React.FC = () => {
    const {definir_musique_courant,definir_toast_message, toggle_modal_programme,moi, programmations, definir_programmations, definir_programmations_du_jour} = useContext(AppContext);
    useEffect(() => {
        if (moi && moi.id) {
            // programmationService.tous_les_programmations_par_id_client(moi.id)
            //     .then(reponse => {
            //         definir_programmations(reponse.data);
            //         definir_toast_message(reponse.status, reponse.message)
            //     })
            //     .catch(erreur => {
            //         console.error(erreur)
            //         if (erreur && erreur?.response?.data?.status) {
            //             definir_toast_message(erreur.response.data.status, erreur.response.data.message);
            //         } else {
            //             if (erreur.code == "ERR_NETWORK") {
            //                 definir_toast_message(400, "Verifier votre connexion internet");
            //             } else {
            //                 definir_toast_message(400, erreur.message);
            //             }
            //         }
            //     })
            
            programmationService.tous_les_programmations_webradios_par_id_client(moi.id)
                .then(reponse => {
                    definir_programmations(reponse.data);
                    definir_toast_message(reponse.status, reponse.message)
                })
                .catch(erreur => {
                    console.error(erreur)
                    if (erreur && erreur?.response?.data?.status) {
                        definir_toast_message(erreur.response.data.status, erreur.response.data.message);
                    } else {
                        if (erreur.code == "ERR_NETWORK") {
                            definir_toast_message(400, "Verifier votre connexion internet");
                        } else {
                            definir_toast_message(400, erreur.message);
                        }
                    }
                })
        }

    },[moi])

    const modifier_programme = (playlist_id: number) => {
        if (playlist_id) {
            programmationService.modifier_programme(moi.id, {playlist_id, user_id:moi.id})
                .then(reponse => {
                })
                .catch(error => {
                    console.error(error);
                    if (error && error?.response?.data?.status) {
                        definir_toast_message(error.response.data.status, error.response.data.message);
                    } else {
                        if (error.code == "ERR_NETWORK") {
                            definir_toast_message(400, "Verifier votre connexion internet");
                        } else {
                            definir_toast_message(400, error.message);
                        }
                    }
                })
        }
    }

    const supprimer_programme = (id:number  ,playlist_id: number) => {
        if (playlist_id) {
            // programmationService.supprimer_programme(id, moi.id, playlist_id, parseInt(moment().format("d")) - 1)
            //     .then(reponse => {
            //         definir_programmations(reponse.data_all);
            //         definir_programmations_du_jour(reponse.data_per_day);
            //         definir_toast_message(reponse.status, reponse.message);
            //     })
            //     .catch(erreur => {
            //         if (erreur && erreur?.response?.data?.status) {
            //             definir_toast_message(erreur.response.data.status, erreur.response.data.message);
            //         } else {
            //             if (erreur.code == "ERR_NETWORK") {
            //                 definir_toast_message(400, "Verifier votre connexion internet");
            //             } else {
            //                 definir_toast_message(400, erreur.message);
            //             }
            //         }
            //     })

            programmationService.supprimer_programme_webradio(id, moi.id, playlist_id, parseInt(moment().format("d")) - 1)
                .then(reponse => {
                    definir_programmations(reponse.data_all);
                    definir_programmations_du_jour(reponse.data_per_day);
                    definir_toast_message(reponse.status, reponse.message);
                })
                .catch(erreur => {
                    if (erreur && erreur?.response?.data?.status) {
                        definir_toast_message(erreur.response.data.status, erreur.response.data.message);
                    } else {
                        if (erreur.code == "ERR_NETWORK") {
                            definir_toast_message(400, "Verifier votre connexion internet");
                        } else {
                            definir_toast_message(400, erreur.message);
                        }
                    }
                })
        }
    }

    return (
            <IonContent>
            {/* <IonPage>
                <HeaderWithContent> */}
                    <div className="pt-36 pb-10">
                        <div className='grid grid-cols-6'>
                            <div className="grid place-content-center">
                                <IonRouterLink routerLink='/playlists'>
                                    <IonIcon className="text-[#EA491D] text-[32px]" icon={chevronBack}/>
                                </IonRouterLink>
                            </div>
                            <div className="col-span-4">
                                <h1 className="text-center text-[] text-3xl text-semibold">Programmations</h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex shadow-md">
                        <div className="basis-8/12 px-5 text-left"><h2>Playlist</h2></div>
                        <div className="basis-2/12 text-left"><h2>Quand?</h2></div>
                        <div className="basis-2/12 text-left"><h3></h3></div>
                    </div>
                    <IonList style={{zIndex:10}} lines="full">
                        {
                            programmations && programmations.length ? 
                            <>
                            {
                                // programmations.map((programme: ProgrammationType, index: number) => (
                                //     <IonItem style={{zIndex:10}}>
                                //         <div className='flex flex-row w-full space-x-3'>
                                //                 <div className='basis-8/12 flex items-center space-x-1 overflow-hidden' onClick={() => {definir_musique_courant(programme.Playlist, index); toggle_modal_programme()}}>
                                //                     <div className='shrink-0 grid mr-5 place-content-center w-8 h-8 rounded-full bg-[#EA491D]'>
                                //                         <IonIcon color='light' icon={play}></IonIcon>
                                //                     </div>
                                //                     <div className='overflow-hidden'>
                                //                         <IonLabel className="truncate">{programme && programme.Playlist && programme.Playlist.name ? programme.Playlist.name : ""}</IonLabel>
                                //                     </div>
                                //                 </div>
                                //                 <div className='basis-2/12'>
                                //                     <p className="text-center">{programme && programme.time ? programme.time : ""}</p>
                                //                 </div>
                                //             <div className='basis-2/12'>
                                //                 <div className="bg-white text-center rounded-full" onClick={() => supprimer_programme(programme.id,programme && programme.Playlist ? programme.Playlist.id : 0)}>
                                //                     <IonIcon style={{color: '#EA491D' }} icon={trashOutline}></IonIcon>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     </IonItem>
                                // ))
                                programmations.map((programme: ProgrammationType, index: number) => (
                                    <IonItem style={{zIndex:10}}>
                                        <div className='flex flex-row w-full space-x-3'>
                                                <div className='basis-8/12 flex items-center space-x-1 overflow-hidden' onClick={() => {definir_musique_courant(programme.Playlist, index); toggle_modal_programme()}}>
                                                    <div className='shrink-0 grid mr-5 place-content-center w-8 h-8 rounded-full bg-[#EA491D]'>
                                                        <IonIcon color='light' icon={play}></IonIcon>
                                                    </div>
                                                    <div className='overflow-hidden'>
                                                        <IonLabel className="truncate">{programme && programme.Webradio && programme.Webradio.name ? programme.Webradio.name : ""}</IonLabel>
                                                    </div>
                                                </div>
                                                <div className='basis-2/12'>
                                                    <p className="text-center">{programme && programme.time ? programme.time : ""}</p>
                                                </div>
                                            <div className='basis-2/12'>
                                                <div className="bg-white text-center rounded-full" onClick={() => supprimer_programme(programme.id,programme && programme.Webradio ? programme.Webradio.id : 0)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={trashOutline}></IonIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </IonItem>
                                ))
                            }
                                <IonItem>
                                    <div className='w-full h-[220px]'></div>
                                </IonItem>
                            </>
                            : null
                        }
                    </IonList>
                    <MiniPlayer/>
                    {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>);
}

export default Programmation;