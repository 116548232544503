import {
    IonPage,
    IonList,
    IonItem,
    IonTitle,
    IonHeader,
    IonContent,
    IonButton,
    IonLabel,
    IonToolbar,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonThumbnail,
    IonRange,
    IonRouterLink,
} from "@ionic/react";
import {
    menu,
    pauseCircleOutline,
    personCircleOutline,
    playSkipBackCircleOutline,
    playSkipBack,
    playSkipForward,
    shuffleOutline,
    starOutline,
    playSkipForwardCircleOutline,
    playBackCircleOutline,
    playForwardCircleOutline,
    playCircleOutline,
} from "ionicons/icons";
import "./accueil.css";
import logo from "../../assets/logo.jpg";
import { useContext } from "react";
import { AppContext } from "../appProvider";
import HeaderWithContent from "../../components/headerWithContent";
import { LocalNotifications } from "@capacitor/local-notifications";
import config from "../../config/config.json";
import iconPrev from "../../assets/images/icon-prev.png";
import iconNext from "../../assets/images/icon-next.png";
import iconPlay from "../../assets/images/icon-play.png";
import favorisBtn from "../../assets/images/favoris-btn.png";
import favorisBtnChecked from "../../assets/images/favoris-btn-checked.png";
import shuffleIcon from "../../assets/images/shuffle-icon.png";
import musiqueService from "../liste_musique/musiqueService";
import { Redirect } from "react-router-dom";
import webradioService from "../playlist/webradioService";

const Accueil: React.FC = () => {
    const {
        aleatoire,
        si_authentique,
        musique_courant,
        pause,
        mettre_en_pause,
        toggle_aleatoire,
        position,
        mis_a_jour_favoris,
        definir_musique_courant,
        mettre_musique_precedent,
        mettre_musique_suivant,
        moi,
        setWebradios
    } = useContext(AppContext);

    // if (si_authentique) {
    //     return <Redirect to="/playlists" />;
    // }

    const notification = () => {
        LocalNotifications.schedule({
            notifications: [
                {
                    title: "bonjour",
                    body: "",
                    id: 1,
                    sound: `${config.API_URL}/API/musique/play/westlife.mp3`,
                },
            ],
        });
    };

    const modifier_favoris = (index: number, sound_id: number) => {
        musiqueService
            .modifier_favoris(moi.id, { sound_id, user_id: moi.id })
            .then((reponse) => {
                definir_musique_courant(
                    {
                        ...musique_courant,
                        is_favoris: !musique_courant.is_favoris,
                    },
                    position
                );
                mis_a_jour_favoris([...reponse.data_all]);
            })
            .catch((erreur) => {
                console.error(erreur);
            });
    };

    const recuperer_tous_les_webradios_demo = () => {
        webradioService.obtenir_tous_les_demos()
                .then((reponse) => {
                    console.log("webradios demo : ", reponse);
                    setWebradios(reponse.data);
                })
                .catch(error => {
                    console.error(error)
                })
    }

    return (
        // <IonPage>
        //     <HeaderWithContent>
                <>
                <div className="fixed h-[calc(50vh - 63px)] mobile320:h-[50vh - 76px)] overflow-y-auto inset-x-0 w-full flex flex-col content-between border border-transparent">
                    <div className="p-5 space-y-5">
                        <h1 className="text-2xl text-[#EA491D]">
                            Qui sommes-nous ?
                        </h1>
                        <p className="text-xs mobile384:text-sm">
                            Un label de musique indépendant qui conçoit des
                            environnements sonores et des musiques d'ambiance
                            pour les HCR depuis 30 ans. Chanteclair vous propose
                            toute une gamme de musiques d’ambiance pour
                            sonoriser vos espaces d’accueil. Notre mission?
                            Donner du style à votre atmosphère pour faire vivre
                            à vos clients un moment de détente privilégié.
                        </p>
                    </div>
                </div>
                <div className="fixed flex flex-col h-[50vh] w-full inset-x-0 bottom-0  overflow-hidden">
                    {si_authentique ? null : (
                        <div className="flex">
                            <div className="mx-auto space-x-2">
                                <IonRouterLink
                                    className="py-2 inline-block px-4 rounded text-white broder-[#EA491D] bg-[#EA491D] text-sm mobile320:text-sm"
                                    routerLink="/inscription"
                                >
                                    Je m'inscrit !
                                </IonRouterLink>
                                <IonRouterLink
                                    className="py-2 inline-block px-4 rounded text-gray-700 border-1 border-gray-400 bg-white text-sm mobile320:text-sm"
                                    routerLink="/playlists"
                                >
                                    Essayer la Démo
                                </IonRouterLink>
                            </div>
                        </div>
                    )}

                    <div className="border-hidden relative">
                        <div className="relative custom-shape-divider-top-1651085402 bg-[#EA491D]">
                            <svg
                                data-name="Layer 1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1200 120"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                                    className="shape-fill"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <div className="bg-[#EA491D] text-white">
                        <ul className="ml-5 flex space-x-2 mobile412:space-x-5">
                            {si_authentique ? (
                                <li>
                                    <IonRouterLink
                                        routerLink="/playlists"
                                        color="light"
                                    >
                                        {musique_courant &&
                                        musique_courant.Playlist &&
                                        musique_courant.Playlist.name
                                            ? musique_courant.Playlist.name
                                            : "Ambiance Musicale"}
                                    </IonRouterLink>
                                </li>
                            ) : (
                                <li>
                                    <IonRouterLink
                                        routerLink="/playlists"
                                        color="light"
                                        onClick={() => recuperer_tous_les_webradios_demo()}
                                    >
                                        {musique_courant &&
                                        musique_courant.Playlist &&
                                        musique_courant.Playlist.name
                                            ? musique_courant.Playlist.name
                                            : "Démo"}
                                    </IonRouterLink>
                                </li>
                            )}
                            {/* <li><IonRouterLink  color='light' onClick={() => notification()}>Notification</IonRouterLink></li> */}
                        </ul>
                    </div>

                    <div className="bg-[#EA491D] grow flex items-center overflow-hidden">
                        <div className="relative mx-auto w-auto h-auto text-center">
                            <div className="grid place-content-center p-8 w-auto h-auto rounded-full border-4 border-white">
                                {pause ? (
                                    // <IonIcon onClick={() => mettre_en_pause(!pause)} className="text-[90px] mobile412:text-[148px]"  color='light' icon={playCircleOutline}/>
                                    <img
                                        onClick={() => mettre_en_pause(!pause)}
                                        src={iconPlay}
                                        alt=""
                                    />
                                ) : (
                                    <IonIcon
                                        onClick={() => mettre_en_pause(!pause)}
                                        className="text-[90px] mobile412:text-[148px]"
                                        color="light"
                                        icon={pauseCircleOutline}
                                    />
                                )}
                            </div>
                            <div className="flex content-center items-center justify- absolute top-[38%] -left-6">
                                {/* <IonRouterLink color='light'>
                                        <div className='bg-white rounded-full grid place-content-center p-3'>
                                            <IonIcon style={{color: "#EA491D"}} size='large' icon={playSkipBack}></IonIcon>
                                        </div>
                                    </IonRouterLink> */}
                                <div
                                    className="w-14 h-14"
                                    onClick={() => mettre_musique_precedent()}
                                >
                                    <img
                                        className="w-full h-full"
                                        src={iconPrev}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="flex content-center items-center justify- absolute top-[38%] -right-6">
                                {/* <IonRouterLink color='light'>
                                        <div className='bg-white rounded-full grid place-content-center p-3'>
                                            <IonIcon style={{color: "#EA491D"}} slot='icon-only' size='large' icon={playSkipForward}></IonIcon>
                                        </div>
                                    </IonRouterLink> */}
                                <div
                                    className="w-14 h-14"
                                    onClick={() => mettre_musique_suivant()}
                                >
                                    <img
                                        className="w-full h-full"
                                        src={iconNext}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='bg-[#EA491D] border ion-no-border border-transparent py-2'>
                            <div className='mx-5 relative ion-no-border'>
                                <IonRange color='light' min={0} max={100}></IonRange>
                                <span className='absolute top-10 left-3 text-white'>0:00</span>
                                <span className='absolute top-10 right-3 text-white'>3:45</span>
                            </div>
                        </div> */}

                    <div className="grid grid-cols-4 grow-0 bg-[#EA491D] gap-5 py-2">
                        <div className="grid place-content-center">
                            {/* {
                                    aleatoire ? 
                                    <div className="w-12 h-12 rounded-full p-2 border-2 border-white" onClick={() => toggle_aleatoire()}>
                                        <img className="w-full h-full" src={shuffleIcon} alt=""/>
                                    </div>
                                    :
                                    <div className="w-12 h-12 rounded-full p-2 border-2 border-transparent" onClick={() => toggle_aleatoire()}>
                                        <img className="w-full h-full" src={shuffleIcon} alt=""/>
                                    </div>
                                } */}
                        </div>
                        <div className="grid content-center text-center col-span-2 overlay-hidden">
                            <h2 className="text-white text-lg truncate">
                                {" "}
                                {musique_courant && musique_courant.title
                                    ? musique_courant.title
                                    : "aucun son"}{" "}
                            </h2>
                        </div>
                        <div className="grid justify-items-center">
                            {musique_courant && musique_courant.is_favoris ? (
                                <div
                                    className="bg-white p-2 rounded-full h-12 w-12"
                                    onClick={() =>
                                        modifier_favoris(
                                            position,
                                            musique_courant.id
                                        )
                                    }
                                >
                                    <img
                                        className="w-full h-full"
                                        src={favorisBtnChecked}
                                        alt=""
                                    />
                                </div>
                            ) : (
                                <div
                                    className="bg-white p-2 rounded-full h-12 w-12"
                                    onClick={() =>
                                        modifier_favoris(
                                            position,
                                            musique_courant.id
                                        )
                                    }
                                >
                                    <img
                                        className="w-full h-full"
                                        src={favorisBtn}
                                        alt=""
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                </>
            // {/* </HeaderWithContent> */}
        // {/* </IonPage> */}
    );
};

export default Accueil;
