import axios from 'axios';
import config from '../../config/config.json';

class AnniversaireService {
    async get_anniversaire_sound(clientId:number) {
        const {data} = await axios.get(`${config.API_URL}/API/client-sound-anniversaire/user/${clientId}`);
        return data;
    }
    async modifier_anniversaire(clientId: number, donnees: object) {
        const {data} = await axios.put(`${config.API_URL}/API/client-sound-anniversaire/update-or-create/${clientId}`, donnees);
        return data;
    }

}

export default new AnniversaireService();