import React,{ useContext, useState, useEffect, useRef } from 'react';
import {    IonRouterLink,
            IonIcon,
            IonModal,
            IonContent,
            IonTitle,
            IonHeader,
            IonLabel,
            IonToolbar,
            IonDatetime,
            IonToast,
            IonSelect,
            IonSelectOption } from '@ionic/react'; 
import {    addOutline, 
            starOutline, 
            shuffleOutline,
            playSkipBack,
            playSkipForward,
            playCircleOutline,
            pauseCircleOutline } from 'ionicons/icons';    
import { AppContext } from '../pages/appProvider';

import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import playlistService from '../pages/playlist/playlistService';
import programmationService from '../pages/programmation/programmationService';
import musiqueService from '../pages/liste_musique/musiqueService';
import shuffleIcon from '../assets/images/shuffle-icon.png';
import favorisBtn from '../assets/images/favoris-btn.png';
import favorisBtnChecked from '../assets/images/favoris-btn-checked.png';
import iconPrev from '../assets/images/icon-prev.png';
import iconNext from '../assets/images/icon-next.png';
import * as Yup from 'yup';
import {Listbox} from '@headlessui/react';
import {SoundType, PlaylistType} from '../pages/types';
import webradioService from '../pages/playlist/webradioService';
import { WebradioType } from '../pages/types';



const MiniPlayer: React.FC = (props: any) => {
    const [playlists, setPlaylists] = useState<PlaylistType[]>([]);
    const [webRadios, setWebradios] = useState<WebradioType[]>([]);
    const [semaine, definir_semaine] = useState([
        "LUNDI","MARDI","MERCREDI","JEUDI","VENDREDI","SAMEDI","DIMANCHE"
    ])
    const [selectedPlaylist, setSelectedPlaylist] = useState({id:0,name:'Choisir une playlist'});
    const [selectedWebradio, setSelectedWebradio] = useState({id:0,name:'Choisir une ambiance musicale'});

    const [en_cours, definir_en_cours] = useState(false);
    const formikRef = useRef<FormikProps<any>>(null);

    const  {    mettre_en_pause,
                pause,
                mettre_musique_precedent,
                mettre_musique_suivant,
                musique_courant,
                moi,
                modal_programme,
                toggle_modal_programme,
                gerer_log,
                aleatoire,
                toggle_aleatoire,
                definir_musique_courant,
                position,
                mis_a_jour_favoris,
                mis_a_jour_favoris_webradios,
                si_authentique,
                definir_programmations,
                definir_programmations_du_jour,
                toast,
                etat_toast,
                fermer_toast,
                definir_toast_message   } = useContext(AppContext);

    useEffect(() => {
        gerer_log("Entrer dans minPlayer", null)
        if (moi && moi.id) {
            webradioService.obtenir_tous_les_webradios(moi.id)
                .then(reponse => {
                    setWebradios(reponse.data);
                })
                .catch(error => {
                    console.error(error)
                })
    
            playlistService.obtenir_tous_les_playlists()
                .then(reponse => {
                    gerer_log("[minPlayer] :", reponse.data);
                    setPlaylists(reponse.data)
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [moi])

    const modifier_favoris = (index:number, sound: SoundType) => {
        if (sound.categorie == "SOUND") {
            musiqueService.modifier_favoris(moi.id, {sound_id: sound.id, user_id:moi.id})
                .then(reponse => {
                    definir_musique_courant({...musique_courant,is_favoris: !musique_courant.is_favoris}, position);
                    mis_a_jour_favoris([...reponse.data_all]);
                })
                .catch(erreur => {
                    console.error(erreur);
                })
            }
            
            if (sound.categorie == "WEBRADIO") {
                webradioService.modifier_favoris_webradio(moi.id, {webradio_id: sound.id, user_id:moi.id})
                .then(reponse => {
                    definir_musique_courant({...musique_courant,is_favoris: !musique_courant.is_favoris}, position);
                    mis_a_jour_favoris_webradios([...reponse.data_all]);
                })
                .catch(erreur => {
                    console.error(erreur);
                })
        }
    }

    const modifier_semaine = (jour: string,numero: number) => {
        let index_jour: number | null | undefined = semaine.findIndex(item => item == jour);
        if (index_jour != undefined && index_jour != null && index_jour >= 0) {
            semaine.splice(index_jour,1);
            if (formikRef && formikRef.current) {
                formikRef.current.setFieldValue("day_of_week", semaine && semaine.length ?  [...semaine] : []);
            }
            definir_semaine([...semaine]);

        } else {
            semaine.splice(numero,0, jour);
            if (formikRef && formikRef.current) {
                formikRef.current.setFieldValue("day_of_week", semaine && semaine.length ?  [...semaine] : []);
            }
            definir_semaine([...semaine]);
        }
    }

    const si_jour_existe = (jour: string) => {
        let jour_existe = semaine.find(item => item == jour);
        if (jour_existe) {
            return true;
        } else {
            return false;
        }
    }
    

    return (<>
            <IonModal isOpen={modal_programme}>
                <IonHeader>
                    <IonToolbar>
                        <div className="flex items-center justify-bettween">
                            <IonTitle className="text-[#EA491D]">Programmation</IonTitle>
                            <div className="pr-5" onClick={() => toggle_modal_programme()}>
                                <IonLabel className="">Fermer</IonLabel>
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className='p-10'>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            webradio_id: "",
                            time: moment().format(),
                            day_of_week: semaine && semaine.length ? [...semaine] : []
                        }}
                        validationSchema={Yup.object({
                            webradio_id: Yup.number().required(),
                            time: Yup.string().required()
                        })}
                        onSubmit={(values) => {
                            definir_en_cours(true);
                            programmationService.ajouter_programme_webradio({...values,time: moment(values.time).format("HH:mm:ss"), user_id: moi.id, number_day_of_week: parseInt(moment().format("d")) - 1})
                                .then(reponse => {
                                    definir_programmations(reponse.data_all)
                                    definir_programmations_du_jour(reponse.data_per_day);
                                    definir_toast_message(reponse.status, reponse.message);
                                    setSelectedPlaylist({id:0,name:'Choisir une playlist'});
                                    definir_en_cours(false);
                                })
                                .catch(erreur => {
                                    if (erreur && erreur?.response?.data?.status) {
                                        definir_toast_message(erreur.response.data.status, erreur.response.data.message);
                                        setSelectedPlaylist({id:0,name:'Choisir une playlist'});
                                        definir_en_cours(false);
                                    } else {
                                        if (erreur.code == "ERR_NETWORK") {
                                            definir_toast_message(400, "Verifier votre connexion internet");
                                            setSelectedPlaylist({id:0,name:'Choisir une playlist'});
                                            definir_en_cours(false);
                                        } else {
                                            definir_toast_message(400, erreur.message);
                                            setSelectedPlaylist({id:0,name:'Choisir une playlist'});
                                            definir_en_cours(false);
                                        }
                                    }
                                })
                        }}
                    >{(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <label className='text-lg text-gray-700 font-semibold'>Ambiance Musicale</label>
                                <div>
                                    {/* <Listbox value={selectedPlaylist} onChange={(e) => {formik.setFieldValue("playlist_id", e.id);setSelectedPlaylist(e)}}>
                                        <Listbox.Button className="bg-[#EA491D] text-white w-full p-2 rounded">{selectedPlaylist.name}</Listbox.Button>
                                        <Listbox.Options className="text-center text-[#EA491D] border-2 border-[#EA491D]">
                                            {playlists.map((playlist) => (
                                            <Listbox.Option
                                                className="active:bg-[#EA491D] active:text-white p-2"
                                                key={playlist.id}
                                                value={playlist}
                                            >
                                                {playlist.name}
                                            </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Listbox> */}

                                    <Listbox value={selectedWebradio} onChange={(e) => {formik.setFieldValue("webradio_id", e.id);setSelectedWebradio(e)}}>
                                        <Listbox.Button className="bg-[#EA491D] text-white w-full p-2 rounded">{selectedWebradio.name}</Listbox.Button>
                                        <Listbox.Options className="text-center text-[#EA491D] border-2 border-[#EA491D]">
                                            {webRadios.map((webradio) => (
                                            <Listbox.Option
                                                className="active:bg-[#EA491D] active:text-white p-2"
                                                key={webradio.id}
                                                value={webradio}
                                            >
                                                {webradio.name}
                                            </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Listbox>


                                </div>
                                {/* <IonSelect placeholder='Veuillez choisir une playlist' onIonChange={(e) => formik.setFieldValue("playlist_id", e.detail.value)}>
                                    {playlists && playlists.length ? 
                                        playlists.map(playlist => (
                                            <IonSelectOption value={playlist.id}>{playlist.name}</IonSelectOption>
                                        ))
                                    : 
                                    <IonSelectOption value=''>Aucun playlist</IonSelectOption>
                                    }
                                </IonSelect> */}
                            </div>
                            <div>
                                <p className='text-lg text-gray-700 font-semibold'>Heure de lecture :</p>
                                <div>
                                    <IonDatetime presentation='time' hourCycle='h23' onIonChange={(e) => formik.setFieldValue("time", e.detail.value)}></IonDatetime>
                                </div>
                            </div>
                            <div>
                                <label className='text-lg text-gray-700 font-semibold'>Jour(s) : </label>
                                <div className="flex flex-wrap mb-5">
                                    <div className={`${ si_jour_existe("LUNDI") ? "bg-[#EA491D]" : "border-2 border-[#EA491D]" } rounded-full text-center  w-12 h-12 grid place-content-center mt-2 mr-2`} onClick={() => modifier_semaine("LUNDI", 0)}><h2 className={`${ si_jour_existe("LUNDI") ? "text-white" : "text-[#EA491D]"} text-2xl`}>L</h2></div>
                                    <div className={`${ si_jour_existe("MARDI") ? "bg-[#EA491D]" : "border-2 border-[#EA491D]" } rounded-full text-center  w-12 h-12 grid place-content-center mt-2 mr-2`} onClick={() => modifier_semaine("MARDI", 1)}><h2 className={`${ si_jour_existe("MARDI") ? "text-white" : "text-[#EA491D]"} text-2xl`}>M</h2></div>
                                    <div className={`${ si_jour_existe("MERCREDI") ? "bg-[#EA491D]" : "border-2 border-[#EA491D]" } rounded-full text-center  w-12 h-12 grid place-content-center mt-2 mr-2`} onClick={() => modifier_semaine("MERCREDI", 2)}><h2 className={`${ si_jour_existe("MERCREDI") ? "text-white" : "text-[#EA491D]"} text-2xl`}>M</h2></div>
                                    <div className={`${ si_jour_existe("JEUDI") ? "bg-[#EA491D]" : "border-2 border-[#EA491D]" } rounded-full text-center  w-12 h-12 grid place-content-center mt-2 mr-2`} onClick={() => modifier_semaine("JEUDI", 3)}><h2 className={`${ si_jour_existe("JEUDI")? "text-white" : "text-[#EA491D]"} text-2xl`}>J</h2></div>
                                    <div className={`${ si_jour_existe("VENDREDI") ? "bg-[#EA491D]" : "border-2 border-[#EA491D]" } rounded-full text-center  w-12 h-12 grid place-content-center mt-2 mr-2`} onClick={() => modifier_semaine("VENDREDI", 4)}><h2 className={`${ si_jour_existe("VENDREDI") ? "text-white" : "text-[#EA491D]"} text-2xl`}>V</h2></div>
                                    <div className={`${ si_jour_existe("SAMEDI") ? "bg-[#EA491D]" : "border-2 border-[#EA491D]" } rounded-full text-center  w-12 h-12 grid place-content-center mt-2 mr-2`} onClick={() => modifier_semaine("SAMEDI", 5)}><h2 className={`${ si_jour_existe("SAMEDI") ? "text-white" : "text-[#EA491D]"} text-2xl`}>S</h2></div>
                                    <div className={`${ si_jour_existe("DIMANCHE") ? "bg-[#EA491D]" : "border-2 border-[#EA491D]" } rounded-full text-center  w-12 h-12 grid place-content-center mt-2 mr-2`} onClick={() => modifier_semaine("DIMANCHE", 6)}><h2 className={`${ si_jour_existe("DIMANCHE") ? "text-white" : "text-[#EA491D]"} text-2xl`}>D</h2></div>
                                </div>
                            </div>
                            <div>
                                <button type='submit' className='bg-[#EA491D] py-2 w-full text-white rounded'>{en_cours ? "En cours ..." : "Sauvegarder"}</button>
                            </div>
                        </form>

                    )}</Formik>
                    </div>
                </IonContent>
            </IonModal>
            <div className='fixed z-50 w-full inset-x-0 bottom-0'>
                <div className='relative'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#EA491D" fillOpacity="1" d="M0,288L60,240C120,192,240,96,360,101.3C480,107,600,213,720,213.3C840,213,960,107,1080,74.7C1200,43,1320,85,1380,106.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                    <div className="absolute bottom-0 inset-x-0 grid grid-cols-6 items-end py-2 w-full">
                        <div className='col-span-5  text-white'>
                            <ul className='ml-5 flex space-x-5'>
                                {si_authentique ? 
                                <li><IonRouterLink routerLink='/playlists' color='light'>{musique_courant && musique_courant.Playlist && musique_courant.Playlist.name ? musique_courant.Playlist.name : "Ambiance Musicale"}</IonRouterLink></li>
                                : 
                                <li><IonRouterLink routerLink='/playlists' color='light'>Démo</IonRouterLink></li>
                                }
                            </ul>
                        </div>
                        <div className="space-y-2">
                            <div className='w-[40px] h-[40px] mx-auto bg-[#EA491D] rounded-full h-12 w-12 grid place-content-center text-center' onClick={() => toggle_modal_programme()}>
                                <IonIcon className="text-[32px] text-white" icon={addOutline}/>
                            </div>
                            <div className='w-[40px] h-[40px] mx-auto bg-white rounded-full h-12 w-12 grid place-content-center text-center'>
                                {/* <IonIcon style={{fontSize: '32px',color: "#EA491D"}} icon={starOutline}/> */}
                                {musique_courant && musique_courant.is_favoris ? 
                                    <div className='bg-white p-2 rounded-full h-12 w-12' onClick={() => modifier_favoris(position, musique_courant)}>
                                        <img className="w-full h-full" src={favorisBtnChecked} alt=""/>
                                    </div>
                                :
                                    <div className='bg-white p-2 rounded-full h-12 w-12' onClick={() => modifier_favoris(position, musique_courant)}>
                                        <img className="w-full h-full" src={favorisBtn} alt=""/>
                                    </div>
                                }

                            </div>
                            {/* <div className=''>
                                {
                                    aleatoire ? 
                                    <div className="w-12 h-12 mx-auto rounded-full p-2 border-2 border-white" onClick={() => toggle_aleatoire()}>
                                        <img className="w-full h-full" src={shuffleIcon} alt=""/>
                                    </div>
                                    :
                                    <div className="mx-auto w-12 h-12 rounded-full p-2 border-2 border-transparent" onClick={() => toggle_aleatoire()}>
                                        <img className="w-full h-full" src={shuffleIcon} alt=""/>
                                    </div>
                                }
                            </div> */}
                        </div>
                    </div>
                    <div className="bg-[#EA491D] h-6"></div>
                </div>

                <div className='grid grid-cols-6 bg-[#EA491D] gap-1 py-2'>
                    {/* <div className='grid justify-items-center'> */}
                        {/* <div onClick={() => mettre_musique_precedent()} className='bg-white rounded-full h-10 w-10 grid place-content-center text-center'>
                            <IonIcon className="text-[32px] text-[#EA491D]" icon={playSkipBack}/>
                        </div> */}


                        {/* <div onClick={() => mettre_musique_precedent()} className="w-12 h-12">
                            <img className="w-full h-full" src={iconPrev} alt="" />
                        </div> */}

                    {/* </div> */}
                    <div className='grid justify-items-center text-center col-span-2'>
                        {/* <IonIcon className='text-[46px] text-white' icon={playCircleOutline}/> */}
                        <div className=''>
                        {pause ? 
                            <IonIcon onClick={() => mettre_en_pause(!pause)}  color='light' style={{fontSize: "72px"}} icon={playCircleOutline}/>
                            :
                            <IonIcon onClick={() => mettre_en_pause(!pause)}  color='light' style={{fontSize: "72px"}} icon={pauseCircleOutline}/>
                            }
                        </div>
                    </div>
                    <div className='grid content-center text-center col-span-3'>
                        <h2 className='text-white text-lg truncate'>{musique_courant && musique_courant.title ? musique_courant.title : "Aucun son"}</h2>
                    </div>
                    {/* <div className='grid justify-items-center'> */}
                        {/* <div onClick={() => mettre_musique_suivant()} className='bg-white rounded-full h-10 w-10 grid place-content-center text-center'>
                            <IonIcon className="text-[32px] text-[#EA491D]"  icon={playSkipForward}/>
                        </div> */}


                        {/* <div onClick={() => mettre_musique_suivant()} className="w-12 h-12">
                            <img className="w-full h-full" src={iconNext} alt="" />
                        </div>
                    </div> */}
                </div>
            </div>
            </>)
}

export default MiniPlayer;