import React, {useEffect, useContext, useState, useRef} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import HeaderWithContent from '../../components/headerWithContent';
import {AppContext} from '../appProvider';
import messageService from './messageService';
import moment from 'moment';
moment.locale('fr');

interface MessageType {
    id: number,
    content: string,
    sender: number,
    to: number,
    to_view: number,
    date_time: string,
    created_at: string,
    updated_at: string
}

const Message: React.FC = () => {
    const {moi,parametre, gerer_erreur,conversation_courant, definir_toast_message, gerer_log, tous_les_messages, mettre_a_jour_messages, definir_messages} = useContext(AppContext);
    const messageRef = useRef<HTMLInputElement>(null);
    const messageContentRef = useRef<HTMLDivElement>(null);

    useEffect(()=> {
        if (moi && moi.id && conversation_courant) {
            messageService.tous_les_messages_par_id_client(moi.id, conversation_courant.to)
                .then(reponse => {
                    // gerer_log("reponse message : ",reponse.data);
                    definir_messages(reponse.data);
                })
                .catch(erreur => {
                    gerer_erreur("erreur : ",erreur);
                })
        }
    }, [moi,conversation_courant]);

    const envoyer_message = () => {
        if (messageRef && messageRef.current && messageRef.current.value) {
            messageService.envoyer_message({
                    content: messageRef && messageRef.current ? messageRef.current.value : "..." ,
                    // sender: moi.id,
                    // to: parametre.admin_message_id,
                    sender: conversation_courant.sender,
                    to: conversation_courant.to
                })
                .then(reponse => {
                    // gerer_log("",reponse);
                    mettre_a_jour_messages([reponse.data]);
                    definir_toast_message(reponse.status, reponse.message);
                    if (messageRef && messageRef.current && messageRef.current.value) {
                        messageRef.current.value = "";
                    }
                })
                .catch(erreur => {
                    if (erreur && erreur?.response?.data?.status) {
                        definir_toast_message(erreur.response.data.status, erreur.response.data.message);
                    } else {
                        if (erreur.code == "ERR_NETWORK") {
                            definir_toast_message(400, "Verifier votre connexion internet");
                        } else {
                            definir_toast_message(400, erreur.message);
                        }
                    }
                })
        }
    }
    return (
        <IonContent>
            {/* <IonPage>
                <HeaderWithContent> */}
                        <div ref={messageContentRef} className="px-2 pt-2 pb-20 space-y-3">
                            {tous_les_messages && tous_les_messages.length && moi ? 
                                tous_les_messages.map((message: MessageType) => {
                                    if (moi.id == message.sender) {
                                        return (<div className="w-full flex justify-end ">
                                                    <div className="w-3/4 flex flex-col">
                                                        <div className="flex justify-end">
                                                            <span className="text-gray-400 text-xs text-left pr-3">{moment(message.date_time).format('MMMM Do YYYY, H:mm:ss')}</span>
                                                        </div>
                                                        <div className="p-3 rounded-xl bg-[#EA491D] text-white">{message.content}</div>
                                                    </div>
                                                </div>)
                                    } else if (moi.id == message.to) {
                                        return (<div className="w-full flex justify-start">
                                                    <div className="w-3/4 flex flex-col">
                                                        <span className="text-gray-400 text-xs pl-3">{moment(message.date_time).format('MMMM Do YYYY, h:mm:ss a')}</span>    
                                                        <div className=" p-3 rounded-xl bg-gray-500 text-white">{message.content}</div>
                                                    </div>
                                                </div>)
                                    }
                                })
                            : null}
                            
                        </div>
                        <div className="fixed bottom-0 inset-x-0">
                            <div className="flex items-center w-full">
                                <input
                                    ref={messageRef} 
                                    className='grow py-2 px-4' 
                                    placeholder='Votre message ...'
                                    type="text"/>
                                <button className='bg-[#EA491D] py-2 px-4 shrink-0 grow-0 text-white' onClick={() => envoyer_message()}>Envoyer</button>
                            </div>
                        </div>
                {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>
            );
}

export default Message;